<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardBody>

          <CDataTable
            :items="list"
            :fields="fields"
            :loading="loading"
            hover
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('update','KLAddPhoto',lastItem, 'Fotoğraf Güncelleme ('+lastItem.ownerName+')')"
            v-on:refresh="filterPhotos(filterParams)"
            columnFilter
          >
            <template #show_details="{item}">
              <td class="py-2">

                <CDropdown
                  toggler-text="İşlemler" color="info"
                  class="m-2"
                >
                  <div role="group">
                    <CDropdownItem @click="openModalEvent('update','KLAddSlider',item, 'Slider Öncelik Gösterim Sırası ('+item.ownerName+')')">
                      Slider Fotoğrafı Olarak - Yayınla/Kaldır
                    </CDropdownItem>
                    <CDropdownItem @click="openModalEvent('update','KLAddPhoto',item, 'Fotoğraf Güncelleme ('+item.ownerName+')')">
                      Fotoğraf Yükle
                    </CDropdownItem>
                    <CDropdownItem  @click="openModalEvent('update','KLProduct',item, 'Ürün Bilgilerini Güncelleme ('+item.ownerName+')')">
                      Ürün Bilgisi Gir
                    </CDropdownItem>
                    <CDropdownItem @click="requestApprove(item)">Telif Onayı Talebi Gönder</CDropdownItem>
                    <CDropdownItem @click="updateApprove(item)">
                      Telif Onayı {{item.approvedFlag ? 'Kaldır' : 'Ver'}}
                    </CDropdownItem>
                    <CDropdownItem @click="updatePublish(item)">
                     {{item.publishableFlag ? 'Yayından Kaldır' : 'Yayına Al'}}
                    </CDropdownItem>
                    <CDropdownItem @click="informUser(item)">Bilgilendirme Maili Gönder</CDropdownItem>
                  </div>
                </CDropdown>
              </td>
            </template>

            <template #id-filter="{item, index}">
                  <CInput
                      col="12"
                      style="margin-bottom: 0;"
                      :value.sync="filterParams.id"
                      v-on:keyup.enter="setFilterParams({id: filterParams.id})"
                    />
            </template>

            <template #approvedFlag-filter="{item, index}">
                    <Multiselect
                        placeholder=""
                        horizontal
                        size="sm"
                        v-model="approvedFlagFilter"
                        select-label="Seçiniz"
                        selected-label="Seçildi"
                        deselect-label="Kaldır"
                        :options="yesNoList"
                        :multiple="false"
                        label="label"
                        track-by="label"
                        @input="() => setFilterParams({approvedFlag: approvedFlagFilter ? approvedFlagFilter.value : null})"
                    />
                </template>

                <template #publishableFlag-filter="{item, index}">
                    <Multiselect
                        placeholder=""
                        horizontal
                        size="sm"
                        v-model="publishableFlagFilter"
                        select-label="Seçiniz"
                        selected-label="Seçildi"
                        deselect-label="Kaldır"
                        :options="yesNoList"
                        :multiple="false"
                        label="label"
                        track-by="label"
                        @input="() => setFilterParams({publishableFlag: publishableFlagFilter ? publishableFlagFilter.value : null} )"
                    />
                </template>

            <template #approvedFlag="{item}">
              <td>
                <CIcon v-if="item.approvedFlag" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #publishableFlag="{item}">
              <td>
                <CIcon v-if="item.publishableFlag" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #description="{item}"> 
              <td style="white-space: nowrap;"> 
                <p v-if="item.description.length > 50" v-c-tooltip="tooltip(item)"> {{ item.description.substring(0,50) + '...'}}</p>
                <p v-else> {{ item.description }} </p>
              </td> 
            </template>
            <template #createTime="{item}"> <td> {{item.createTime && dateFormat(item.createTime) }} </td> </template>
            <template #updateTime="{item}"> <td> {{ item.updateTime && dateFormat(item.updateTime) }} </td> </template>
            <template #approveRequestTime="{item}"> <td> {{ item.approveRequestTime && dateFormat(item.approveRequestTime) }} </td> </template>
            <template #ownerLastInformedTime="{item}"> <td> {{ item.ownerLastInformedTime && dateFormat(item.ownerLastInformedTime) }} </td> </template>

            <template #urlx1="{item}">
              <td><img width="50" :src="imageURLTargetPath  + item.id  + '.' + item.fileExtension" @click="openPhoto(imageURLTargetPath  + item.id + '.' + item.fileExtension)"></td>
            </template>
            <template #urlx2="{item}">
              <td ><img v-if="item.urlx2 !== ''" :src="item.urlx2" @click="openPhoto(imageURLPath + item.id+ '.' + item.fileExtension)"></td>
            </template>
          </CDataTable>
          <CPagination
            v-show="pages>0"
            :pages="pages"
            :active-page.sync="activePage"
          />
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="photoCrud"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="photoCrud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      size="xl"
    />
  </CRow>
</template>

<script>
import moment from "moment";
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import { prepareFilterParams } from "@/helpers/custom"
import Multiselect from 'vue-multiselect'

export default {
  name: 'KaftLoverPhoto',
  components:{ FormModal, ConfirmModal, Multiselect },
  computed: {
    list(){
      return this.$store.getters.kaftLovers;
    },
    loading(){
      return this.$store.getters.socialLoading;
    },
  }, 
  data() {
    return {
      imageURLPath: process.env.VUE_APP_PHOTO_IMAGE_URL_PATH,
      imageURLTargetPath: process.env.VUE_APP_PHOTO_IMAGE_URL_TARGET_PATH,
      modalTitle: '',
      actionType: '',
      module: 'social',
      form: 'KLAddSlider',
      approvedFlagFilter: null,
      publishableFlagFilter: null,
      yesNoList: [{value:true, label: "Evet"}, {value:false, label: "Hayır"}, ],
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openModalConfirm: false,
      lastItem: {},
      activePage: 1,
      pages: 1,
      page: 1,
      itemsPerPage: 10,
      filterParams: {},
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id', label: 'Id', _style: 'font-size:12px'},
        {key: 'approvedFlag', label: 'Onaylanmışmı?', _style: 'font-size:12px'},
        {key: 'publishableFlag', label: 'Yayındamı?', _style: 'font-size:12px'},
        {key: 'mainPagePriority', label: 'Slider', _style: 'font-size:12px', filter: false},
        {key: 'ownerName', label: 'Kullanıcı', _style: 'font-size:12px', filter: false},
        {key: 'ownerEmail', label: 'Kullanıcı_Email', _style: 'font-size:12px', filter: false},
        {key: 'description', label: 'Açıklama', _style: 'font-size:12px', filter: false},
        {key: 'fileHeight', label: 'Yükseklik', _style: 'font-size:12px', filter: false},
        {key: 'fileWidth', label: 'Genişlik', _style: 'font-size:12px', filter: false},
        {key: 'productNames', label: 'Ürün', _style: 'font-size:12px', filter: false},
        {key: 'createTime', label: 'Oluşturulma_Tarihi', _style: 'font-size:12px', filter: false},
        {key: 'urlx1', label: 'Fotoğraf', _style: 'font-size:12px', filter: false},
        {key: 'approveRequestTime', label: 'Onay_Maili_G.', _style: 'font-size:12px', filter: false},
        {key: 'ownerLastInformedTime', label: 'Bilg.Maili_G.', _style: 'font-size:12px', filter: false},
        {key: 'updateTime', label: 'Düzenlenme_Tarihi', _style: 'font-size:12px', filter: false},
      ],
      filters: [
        {
          type: 'numeric',
          dataIndex: 'id',
          field: 'id',
          comparison: 'eq'
        },
        {
          type: 'boolean',
          dataIndex: 'approvedFlag',
          field: 'approvedFlag',
        },
        {
          type: 'boolean',
          dataIndex: 'publishableFlag',
          field: 'publishableFlag',
        }
      ],

    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
    activePage: function(newVal){
      this.page = newVal
      this.filterPhotos(this.filterParams)
    }
  },
  methods: {
    rowClicked(item) {
      this.lastItem = item;
    },

    setFilterParams: function(params){
      this.filterParams = {...this.filterParams, ...params};

      if(this.filterParams.approvedFlag == null)
        delete this.filterParams.approvedFlag

      if(this.filterParams.publishableFlag == null)
        delete this.filterParams.publishableFlag

      this.filterPhotos(this.filterParams)
    },

    tooltip(item){
      if(item.description && item.description.length > 50)
        return {content: item.description, placement: 'bottom'}
      return
    },
    dateFormat(data){
      return moment(data).format('DD.MM.YYYY hh:mm:ss')
    },
    openModalEvent(actionType, form, data, title, desc){
      if(form == 'KLProduct'){
        let KLProduct = this.$store.getters.activeProducts.find(ac => ac.id == data.productId)
        data.product = KLProduct ? KLProduct.name : null
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    photoCrud: async function(item, actionType ){
      if(this.form == 'KLAddPhoto'){
        const formData = new FormData();
        formData.append("file", item.file);
        formData.append("fileid", item.id);
        formData.append("extension", item.file.name.split('.').pop());
        await this.$store.dispatch('kaftLover_upload', formData)
      }else if(actionType == 'requestApprove'){
        let params = {
          photoId: item.id,
          ownerEmail: item.ownerEmail,
          securityCodeParam: '342sdsd35'
        }
        await this.$store.dispatch('kaftLover_requestApprove', params)
      }else if(actionType == 'informUser'){
        let params = {
          photoId: item.id,
          ownerEmail: item.ownerEmail,
          securityCodeParam: '342sdsd35'
        }
        await this.$store.dispatch('kaftLover_informUser', params)
      }else{
        let params = {
          actionType: actionType,
          id: item.id,
          mainPriorty: item.mainPagePriority,
          productNames: item.product ? item.product : item.productNames[0],
        }
        if(item.form && item.form == 'updateApprove'){
          params.approvedFlag = !item.approvedFlag
        }
        if(item.form && item.form == 'updatePublish'){
          params.publishableFlag = !item.publishableFlag
        }
        await this.$store.dispatch('kaftLover_action', params)
      }
      if(this.$store.getters.socialStatus.success) {
        this.filterPhotos(this.filterParams);
        this.openModalEvent()
      }
    },
    informUser: function(data){
      let msg = { header: 'Dikkat', color: 'warning'}
      if(!data.approvedFlag){
        msg.msg = 'Fotoğraf onaylı değildir. Lütfen fotoğraf için onay talebi alınız.'
      }else if(!data.urlx2 === ''){
        msg.msg = 'Fotoğraf düzenlenmemiştir.'
      }else if(data.productName == ''){
        msg.msg = 'Ürün bilgisi girilmemiştir. Lütfen fotoğrafın hangi ürüne ait olduğunu seçiniz.'
      }else if(!data.publishableFlag){
        msg.msg = 'Fotoğraf yayında değildir. Lütfen fotoğrafı yayına alınız.'
      }else{
        let msg = data.id + ' id\'li fotoğraf için <b>'+data.ownerEmail+'</b> adresine bilgilendirme maili göndermek üzeresin. Bu işlemi yapmak istediğine emin misin?'
        this.openModalEvent('informUser', 'confirm', data, 'Telif Onay Talebi Gönder', msg)
        return
      }
      this.$store.commit('showToast', msg)

    },
    requestApprove: function(data){
      let msg = { header: 'Dikkat', color: 'warning'}
      if(data.approvedFlag){
        msg.msg = 'Fotoğraf zaten onaylamış.'
      }else if(!data.urlx2 === ''){
        msg.msg = 'Fotoğraf düzenlenmemiştir.'
      }else if(data.productName == ''){
        msg.msg = 'Ürün bilgisi girilmemiştir. Lütfen fotoğrafın hangi ürüne ait olduğunu seçiniz.'
      }else{
        let msg = data.id + ' id\'li fotoğraf için <b>'+data.ownerEmail+'</b> adresine telif onay talebi göndermek üzeresin. Bu işlemi yapmak istediğine emin misin?'
        this.openModalEvent('requestApprove', 'confirm', data, 'Telif Onay Talebi Gönder', msg)
        return
      }
      this.$store.commit('showToast', msg)
    },
    updateApprove: function(data){
      data.form = 'updateApprove'
      let msg = data.approvedFlag ? 'Telif onayını kaldırmak üzeresin. Bu işlemi yapmak istediğine emin misin?' : 'Telif onayı vermek üzeresin bu işlemi yapmak istediğine emin misin?'
      this.openModalEvent('update', 'confirm',data, 'Telif Onayı', msg)
    },
    updatePublish: function(data){
      data.form = 'updatePublish'
      let msg = data.publishableFlag ? 'Yayından kaldırmak üzeresin. Bu işlemi yapmak istediğine emin misin?' : 'Yayına almak üzeresin bu işlemi yapmak istediğine emin misin?'
      this.openModalEvent('update', 'confirm',data, 'Yayınlama İşlemleri', msg)
    },
    openPhoto(item) {
      window.open(item, '_blank');
    },
    async kaftLoverPhotos() {
      let params = {
        "start": (this.page - 1 )* this.itemsPerPage,
        "limit": this.itemsPerPage,
      }
      await this.$store.dispatch('kaftLovers_list', params)
      if(this.$store.getters.kaftLoversCount > 10){
        this.pages = Math.ceil(this.$store.getters.kaftLoversCount / this.itemsPerPage)
      }
    },

    async activeProducts() {
      await this.$store.dispatch('activeProduct_list')
    },

    async filterPhotos(params) {

      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      if(!formData) {
        formData = new FormData();
      }

      formData.append("start", (this.page - 1 )* this.itemsPerPage)
      formData.append("limit", this.itemsPerPage)

      await this.$store.dispatch('kaftLovers_list', formData)
      
      if(this.$store.getters.kaftLoversCount > 10){
        this.pages = Math.ceil(this.$store.getters.kaftLoversCount / this.itemsPerPage)
      }
    },

  },
  created() {
    this.activeProducts();
    this.filterPhotos(this.filterParams);
  }
}
</script>


